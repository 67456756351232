<template>
  <div class="container-main-content signup-mail">
    <div class="content-inner">
      <Header />
      <div class="content-inner__body">
        <h4 class="content-inner__body-title mb-4">Vui lòng xác thực email</h4>
        <p class="f14 mb-2">
          Thư xác thực tài khoản đã được gửi tới
          <b>{{ email }}.</b>
        </p>
        <p class="f14 mb-0">Email xác thực có hiệu lực trong 12 tiếng.</p>
        <p class="f14 mb-4">(Lưu ý kiểm tra trong hòm thư Spam/Junk)</p>
        <div class="content-inner__body-mail d-flex mb-4">
          <a href="https://mail.google.com" target="_blank">
            <div class="content-inner__body-mail-item me-4">
              <img src="../../assets/images/gmail.svg" class="me-2" />
              <span class="f14">Mở Gmail</span>
            </div>
          </a>
          <a href="https://outlook.office.com" target="_blank">
            <div class="content-inner__body-mail-item">
              <img src="../../assets/images/outlook.png" class="me-2" />
              <span class="f14">Mở Outlook</span>
            </div>
          </a>
        </div>
        <button
          type="button"
          class="btn btn-primary"
          @click="resendConfirmLink"
        >
          GỬI LẠI
        </button>
      </div>
      <Footer />
    </div>

    <Loading
      :active="loading.isLoading"
      :can-cancel="true"
      :is-full-page="loading.fullPage"
      color="#3E69F6"
    >
    </Loading>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import { UserService } from "../../services/user.service";

export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      currentQueries: window.location.href.includes("?")
        ? window.location.href.substring(window.location.href.indexOf("?"))
        : "",
      email: this.$route.query.email,
      userId: this.$route.params.userId,
      loading: {
        isLoading: false,
        fullPage: true,
      },
    };
  },
  methods: {
    async resendConfirmLink() {
      try {
        this.loading.isLoading = true;
        const response = await UserService.resendConfirmLink(this.userId);
        if (response.code === "SUCCESS") {
          window.location.reload();
        } else {
          alert(response.data.code);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading.isLoading = false;
      }
    },
  },
};
</script>
